/* Font Sizes */
$font-size-extra-extra-small: 1.25rem;
$font-size-extra-small: 1.3333333333333333rem;
$font-size-small: 1.4166666666666667rem;
$font-size-medium: 1.5rem;
$font-size-large: 1.6666666666666667rem;
$font-size-extra-large: 1.8333333333333333rem;
$font-size-extra-extra-large: 2rem;

/* Line Heights */
$font-line-height-medium: 1.4583333333333333rem;

/* Font Weights */
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

/* Font Styles */
$font-family-verdana: 'Verdana', sans-serif;
$font-family-lato: 'Lato', sans-serif;
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-proxima-nova: 'Proxima Nova', sans-serif;
$font-family-source-sans-pro: 'Source Sans Pro', sans-serif;
$font-family-dm-sans: 'DM Sans', sans-serif;
