.mat-snack-bar-container.info {
  background-color: #2196f3;
}

.mat-snack-bar-container.warning {
  background-color: #ff9800;
}

.mat-snack-bar-container.error {
  background-color: #f44336;
}
