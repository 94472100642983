@import './typography.scss';
@import './colors.scss';

/* Base button styles */
button.oss-button {
  font-weight: $font-weight-bold;
  font-size: 2rem;
  line-height: 2.5833333333333335rem;
  width: 18.229166666666668rem;
  height: 5.208333333333333rem;
  border-radius: 0.6666666666666666rem;
}

/* Button width styles */
button.oss-button-width-xs {
  width: 10.416666666666666rem;
}
button.oss-button-width-s {
  width: 14.291666666666666rem;
}
button.oss-button-width-m {
  width: 18.229166666666668rem;
}
button.oss-button-width-l {
  width: 22.166666666666668rem;
}
button.oss-button-width-xl {
  width: 26.041666666666668rem;
}
button.oss-button-width-xxl {
  width: 29.916666666666668rem;
}

/* Normal variant styles */
button.oss-button-normal {
  font-family: $font-family-source-sans-pro;
  background-color: $color-primary;
  color: $color-white;
  box-shadow:
    0rem 0.16666666666666666rem 0.16666666666666666rem 0rem rgba($color-black, 0.25),
    0rem 0.08333333333333333rem 0.4583333333333333rem 0rem rgba(#4541a4, 0.06);

  // &:hover {
  //   background-color: $color-hovered;
  // }

  &:active {
    background-color: $color-pressed;
  }

  &:disabled {
    background-color: $color-disabled;
    color: $color-disabled-text;
  }
}

/* Outline variant styles */
button.oss-button-outline {
  font-family: $font-family-dm-sans;
  background-color: $color-white;
  color: $color-primary;
  border: 0.16666666666666666rem solid $color-primary;
  box-shadow:
    0rem 0.08333333333333333rem 0.4583333333333333rem 0rem rgba(#4541a4, 0.06),
    0rem 0.16666666666666666rem 0.4166666666666667rem 0rem rgba(#1f2559, 0.07);

  // &:hover {
  //   background-color: $color-outline-hovered;
  //   border: 0.08333333333333333rem solid $color-primary;
  // }

  &:active {
    background-color: transparent;
    color: $color-outline-pressed-border-and-text;
    border: 0.08333333333333333rem solid $color-outline-pressed-border-and-text;
  }

  &:disabled {
    background-color: transparent;
    color: $color-grey-7;
    border: 0.08333333333333333rem solid $color-grey-7;
  }
}

/* Action button styles */
button.oss-button-action {
  font-family: $font-family-open-sans;

  &-primary {
    background-color: $color-primary;
    color: $color-white;
    box-shadow:
      0rem 0.16666666666666666rem 0.16666666666666666rem 0rem rgba($color-black, 0.25),
      0rem 0.08333333333333333rem 0.4583333333333333rem 0rem rgba(#4541a4, 0.06);
  }

  &-secondary {
    background-color: $color-white;
    color: $color-primary;
    border-radius: 4.166666666666667rem;
    box-shadow: 0rem, 0.2916666666666667rem, 0.16666666666666666rem, 0rem, rgba($color-black, 0.3);
  }

  &-outline {
    background-color: transparent;
    color: $color-primary;
    border: 0.16666666666666666rem solid $color-primary;
    box-shadow: none;
  }

  // &:hover {
  //   background-color: $color-hovered;
  //   color: $color-white;
  //   box-shadow:
  //     0rem 0.08333333333333333rem 0.4583333333333333rem 0rem rgba(#4541a4, 0.06),
  //     0rem 0.16666666666666666rem 0.4166666666666667rem 0rem rgba(#1f2559, 0.07);
  // }

  &:active {
    background-color: $color-pressed;
    color: $color-white;
    box-shadow:
      0rem 0.08333333333333333rem 0.4583333333333333rem 0rem rgba(#4541a4, 0.06),
      0rem 0.16666666666666666rem 0.4166666666666667rem 0rem rgba(#1f2559, 0.07);
  }

  &:disabled {
    background-color: $color-disabled;
    color: #646464;
    box-shadow:
      0rem 0.08333333333333333rem 0.4583333333333333rem 0rem rgba(#4541a4, 0.06),
      0rem 0.16666666666666666rem 0.4166666666666667rem 0rem rgba(#1f2559, 0.07);
    border: none;
  }
}
