/* Brand Colors */
$color-primary: #092d74;
$color-secondary-1: #000000;
$color-secondary-2: #b3cee2;
$color-secondary-4: #cf152d;

/* Normal Button Colors */
$color-pressed: #299650;
$color-hovered: rgba($color-pressed, 0.7);
$color-disabled: #d9d9d9;
$color-disabled-text: #f3f4f5;

/* Outline Button Colors */
$color-outline-hovered: #779ee2;
$color-outline-pressed-border-and-text: #1f6bec;

/* Grey Colors */
$color-black: #000000;
$color-grey-1: #282a30;
$color-grey-2: #3a3d43;
$color-grey-3: #4d4f56;
$color-grey-4: #5f606a;
$color-grey-5: #7b7b83;
$color-grey-6: #9c9ba1;
$color-grey-7: #c5c4ca;
$color-white: #ffffff;

/* State Colors */
$color-info: #1f6bec;
$color-success: #299650;
$color-warning: #f8d000;
$color-error: #cf152d;

/* new colors */
$color-new-green-default: #088406;
$color-new-green-active: #033502;
$color-new-disabled-text: #9d9d9d;
$color-new-disabled-background: #d9d9d9;
$color-new-blue-default: #092d74;
$color-new-blue-active: #000c23;
