@import './theme.scss';
@import './oss-button.scss';
@import './snackbar.scss';
@import '../styles/typography.scss';
@import '../styles/colors.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.hide-scrollbar {
  overflow: hidden;
}
